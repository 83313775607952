var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    }
  }, [_c('form', {
    attrs: {
      "id": "form-edit"
    },
    on: {
      "submit": _vm.onSubmit
    }
  }, [_c('div', {
    staticClass: "bg-white w-full rounded-lg mt-4 mb-3 px-4 py-2.5"
  }, [_c('label', [_c('router-link', {
    staticClass: "text-gray-700 font-semibold",
    attrs: {
      "to": {
        name: _vm.BookPath.name
      }
    }
  }, [_vm._v(" Books "), _c('i', {
    staticClass: "fa-solid fa-chevron-right text-xs mr-2 text-gray-500"
  })]), _c('span', {
    staticClass: "text-gray-500"
  }, [_vm._v("Edit")])], 1), _c('h4', {
    staticClass: "text-xl font-semibold"
  }, [_vm._v("Edit Book")])]), _c('div', {
    staticClass: "bg-white w-full rounded-lg mt-4 mb-3 px-4 py-4"
  }, [_c('h4', {
    staticClass: "text-xl font-bold mb-4"
  }, [_vm._v("Edit Book")]), _c('div', {
    staticClass: "flex pb-2 justify-between w-full"
  }, [_c('div', {
    staticClass: "w-1/2 pr-10"
  }, [_c('div', {
    staticClass: "block"
  }, [_c('label', {
    staticClass: "font-semibold text-gray-600"
  }, [_vm._v("Display Name (Item’s Title)")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.name,
      expression: "form.name"
    }],
    staticClass: "w-full bg-gray-50 rounded-lg border",
    attrs: {
      "id": "display",
      "type": "text",
      "placeholder": "Enter Display Name",
      "required": ""
    },
    domProps: {
      "value": _vm.form.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "grid gap-4 grid-cols-2 pt-3"
  }, [_c('div', [_c('label', {
    staticClass: "font-semibold text-gray-600 block"
  }, [_vm._v("Edition Code")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.edition_code,
      expression: "form.edition_code"
    }],
    staticClass: "w-full bg-gray-50 rounded-lg border",
    attrs: {
      "id": "display",
      "type": "text",
      "placeholder": "Edition Code",
      "required": ""
    },
    domProps: {
      "value": _vm.form.edition_code
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "edition_code", $event.target.value);
      }
    }
  })]), _c('div', [_c('label', {
    staticClass: "font-semibold text-gray-600 block"
  }, [_vm._v("Slug")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.slug,
      expression: "form.slug"
    }],
    staticClass: "w-full bg-gray-50 rounded-lg border",
    attrs: {
      "id": "display",
      "type": "text",
      "placeholder": "Slug",
      "required": ""
    },
    domProps: {
      "value": _vm.form.slug
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "slug", $event.target.value);
      }
    }
  })]), _c('div', [_c('label', {
    staticClass: "font-semibold text-gray-600 block"
  }, [_vm._v("Item Status")]), _c('t-select', {
    staticClass: "w-full bg-gray-50 rounded-lg border",
    attrs: {
      "id": "display",
      "options": _vm.itemStatusOptions,
      "placeholder": "Item Status",
      "required": ""
    },
    model: {
      value: _vm.form.item_status,
      callback: function ($$v) {
        _vm.$set(_vm.form, "item_status", $$v);
      },
      expression: "form.item_status"
    }
  })], 1), _c('div', [_c('label', {
    staticClass: "font-semibold text-gray-600 block"
  }, [_vm._v("Issue Number")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.issue_number,
      expression: "form.issue_number"
    }],
    staticClass: "w-full bg-gray-50 rounded-lg border",
    attrs: {
      "id": "display",
      "type": "text",
      "disabled": _vm.userData.role_id === 5,
      "placeholder": "Enter Issue Number",
      "required": ""
    },
    domProps: {
      "value": _vm.form.issue_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "issue_number", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "mt-3 pr-1"
  }, [_c('label', {
    staticClass: "font-semibold text-gray-600 block"
  }, [_vm._v("Author")]), _c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Enter Author",
      "open-direction": "bottom",
      "options": _vm.authorSearchOptions,
      "searchable": true,
      "loading": _vm.isAuthorSearch,
      "close-on-select": true,
      "multiple": true
    },
    on: {
      "search-change": _vm.authorFind,
      "input": _vm.authorSelected
    },
    model: {
      value: _vm.form.author,
      callback: function ($$v) {
        _vm.$set(_vm.form, "author", $$v);
      },
      expression: "form.author"
    }
  })], 1)]), _c('div', {
    staticClass: "w-1/2 pl-10"
  }, [_c('div', [_c('label', {
    staticClass: "font-semibold text-gray-600 block"
  }, [_vm._v("Languages")]), _c('multiselect', {
    attrs: {
      "placeholder": "Enter languages",
      "open-direction": "bottom",
      "label": "name",
      "track-by": "name",
      "custom-label": _vm.nameWithISO,
      "options": _vm.languagesSearchOptions,
      "searchable": true,
      "close-on-select": true,
      "multiple": true,
      "required": ""
    },
    model: {
      value: _vm.form.languages,
      callback: function ($$v) {
        _vm.$set(_vm.form, "languages", $$v);
      },
      expression: "form.languages"
    }
  })], 1), _c('div', {
    staticClass: "mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-gray-600 block"
  }, [_vm._v("Countries")]), _c('multiselect', {
    attrs: {
      "placeholder": "Enter Countries",
      "open-direction": "bottom",
      "label": "name",
      "track-by": "name",
      "custom-label": _vm.nameWithISO,
      "options": _vm.countriesSearchOptions,
      "loading": _vm.isCountriesSearch,
      "searchable": true,
      "close-on-select": true,
      "multiple": true
    },
    model: {
      value: _vm.form.countries,
      callback: function ($$v) {
        _vm.$set(_vm.form, "countries", $$v);
      },
      expression: "form.countries"
    }
  })], 1), _c('div', {
    staticClass: "mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-gray-600 block"
  }, [_vm._v("Categories")]), _c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Enter Categories",
      "open-direction": "bottom",
      "options": _vm.categoriesSearchOptions,
      "loading": _vm.isCategoriesSearch,
      "searchable": true,
      "close-on-select": true,
      "multiple": true
    },
    on: {
      "search-change": _vm.categoriesFind
    },
    model: {
      value: _vm.form.categories,
      callback: function ($$v) {
        _vm.$set(_vm.form, "categories", $$v);
      },
      expression: "form.categories"
    }
  })], 1)])]), _c('div', {
    staticClass: "border-t border-b pb-4 flex-col w-full justify-start pt-4 mt-4"
  }, [_c('h4', {
    staticClass: "text-xl font-bold mt-2 mb-4 block"
  }, [_vm._v("Upload Asset")]), _c('div', {
    staticClass: "flex-col w-1/2 pr-10"
  }, [_c('label', {
    staticClass: "font-semibold text-gray-600 block"
  }, [_vm._v("Display Name (Item’s Title)")]), _c('input', {
    ref: "fileInput",
    staticClass: "border w-full rounded-lg",
    attrs: {
      "type": "file",
      "placeholder": "No file chosen"
    },
    on: {
      "change": _vm.handleFileChange
    }
  }), _c('span', {
    staticClass: "text-gray-500 text-sm mt-2 pb-2 block"
  }, [_vm._v("Max Size 150MB, File Size: 0 Bytes, Current File Size: 0 Bytes")])]), _c('h4', {
    staticClass: "text-xl font-bold capitalize block border-t w-full mt-4 pt-3"
  }, [_vm._v("Detail")]), _c('div', {
    staticClass: "justify-start flex"
  }, [_c('div', {
    staticClass: "w-1/2 pr-10"
  }, [_c('DatetimeCustom', {
    attrs: {
      "label": 'Release Date (GMT ' + _vm.timezoneString() + ')'
    },
    model: {
      value: _vm.form.release_date,
      callback: function ($$v) {
        _vm.$set(_vm.form, "release_date", $$v);
      },
      expression: "form.release_date"
    }
  }), _c('DatetimeCustom', {
    staticClass: "mt-3",
    attrs: {
      "label": 'Release Schedule (GMT ' + _vm.timezoneString() + ')'
    },
    model: {
      value: _vm.form.schedule_date,
      callback: function ($$v) {
        _vm.$set(_vm.form, "schedule_date", $$v);
      },
      expression: "form.schedule_date"
    }
  }), _c('div', {
    staticClass: "grid gap-4 grid-cols-2 mt-3"
  }, [_c('div', [_c('div', {
    staticClass: "mt-0"
  }, [_c('label', {
    staticClass: "font-semibold text-gray-600 mb-3"
  }, [_vm._v("Printed Price Currency")]), _c('t-select', {
    attrs: {
      "id": "currency",
      "options": _vm.currencys,
      "required": ""
    },
    model: {
      value: _vm.form.currency,
      callback: function ($$v) {
        _vm.$set(_vm.form, "currency", $$v);
      },
      expression: "form.currency"
    }
  })], 1), _c('div', {
    staticClass: "mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-gray-600 mb-3"
  }, [_vm._v("Content Type")]), _c('t-select', {
    staticClass: "bg-gray-50",
    attrs: {
      "id": "content_type",
      "options": _vm.contents,
      "required": ""
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1), _c('div', {
    staticClass: "mt-3"
  }, [_c('label', {
    staticClass: "font-semibold mb-3 text-gray-600"
  }, [_vm._v("Distribution Countries")]), _c('t-select', {
    attrs: {
      "id": "distribution",
      "options": _vm.distributionSearchOptions,
      "disabled": "",
      "required": ""
    },
    model: {
      value: _vm.form.distribution,
      callback: function ($$v) {
        _vm.$set(_vm.form, "distribution", $$v);
      },
      expression: "form.distribution"
    }
  })], 1)]), _c('div', [_c('div', {
    staticClass: "mt-0"
  }, [_c('label', {
    staticClass: "font-semibold mb-3 text-gray-600"
  }, [_vm._v("Printed Price")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.price,
      expression: "form.price"
    }],
    staticClass: "w-full bg-gray-50 rounded-lg border",
    attrs: {
      "id": "price",
      "type": "number",
      "step": "any",
      "placeholder": "99000 (no sparator)",
      "required": ""
    },
    domProps: {
      "value": _vm.form.price
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "price", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "mt-3"
  }, [_c('label', {
    staticClass: "font-semibold mb-3 text-gray-600"
  }, [_vm._v("Parental Control")]), _c('t-select', {
    staticClass: "bg-gray-50",
    attrs: {
      "id": "parentals",
      "options": _vm.parentals,
      "required": ""
    },
    model: {
      value: _vm.form.parental,
      callback: function ($$v) {
        _vm.$set(_vm.form, "parental", $$v);
      },
      expression: "form.parental"
    }
  })], 1), _c('div', {
    staticClass: "mt-3"
  }, [_c('label', {
    staticClass: "font-semibold mb-3 text-gray-600"
  }, [_vm._v("Reading Direction")]), _c('t-select', {
    attrs: {
      "id": "readings",
      "options": _vm.readings,
      "required": ""
    },
    model: {
      value: _vm.form.reading,
      callback: function ($$v) {
        _vm.$set(_vm.form, "reading", $$v);
      },
      expression: "form.reading"
    }
  })], 1)])]), _c('div', {
    staticClass: "flex justify-start"
  }, [_c('div', {
    staticClass: "mr-3"
  }, [_c('t-checkbox', {
    attrs: {
      "value": true,
      "unchecked-value": false
    },
    model: {
      value: _vm.form.is_active,
      callback: function ($$v) {
        _vm.$set(_vm.form, "is_active", $$v);
      },
      expression: "form.is_active"
    }
  }), _c('label', {
    staticClass: "text-gray-600 font-semibold mt-3 ml-2"
  }, [_vm._v("Active")])], 1), !_vm.isProfileVendor ? _c('div', [!_vm.isProfileVendor ? _c('t-checkbox', {
    attrs: {
      "value": "true",
      "unchecked-value": "false"
    },
    model: {
      value: _vm.form.is_internal_content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "is_internal_content", $$v);
      },
      expression: "form.is_internal_content"
    }
  }, [_vm._v(" Internal Content ")]) : _vm._e(), _c('label', {
    staticClass: "text-gray-600 font-semibold mt-3 ml-2"
  }, [_vm._v("Internal Content")])], 1) : _vm._e()]), _c('div', {
    staticClass: "flex justify-start mt-2"
  }, [_c('button', {
    staticClass: "bg-blue-700 p-2 px-2 text-white rounded-lg",
    attrs: {
      "type": "submit",
      "form": "form-edit",
      "hidden": _vm.isLoading
    }
  }, [_vm._v(" Save ")]), _c('button', {
    staticClass: "bg-white border-blue-700 text-blue-700 border-l border-t border-b border-r rounded-lg ml-3 px-3",
    attrs: {
      "type": "reset"
    }
  }, [_vm._v("Reset Form")]), _c('button', {
    staticClass: "bg-white p-2 px-3 text-gray-800 border rounded-lg ml-3"
  }, [_c('router-link', {
    staticClass: "text-capitalize text-gray-800 font-normal",
    attrs: {
      "to": {
        name: _vm.BookPath.name
      }
    }
  }, [_vm._v(" Cancel")])], 1)])], 1), _c('div', {
    staticClass: "w-1/2 pl-10"
  }, [_c('div', {
    staticClass: "block"
  }, [_c('label', {
    staticClass: "font-semibold mb-3 text-gray-600"
  }, [_vm._v("International Standard Book Number (ISBN)")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.isbn,
      expression: "form.isbn"
    }],
    staticClass: "w-full bg-gray-50 rounded-lg border",
    attrs: {
      "id": "price",
      "type": "number",
      "step": "any",
      "placeholder": "Enter ISBN",
      "required": ""
    },
    domProps: {
      "value": _vm.form.isbn
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "isbn", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "block mt-3"
  }, [_c('label', {
    staticClass: "font-semibold mb-3 text-gray-600"
  }, [_vm._v("Description")]), _c('t-textarea', {
    attrs: {
      "id": "description",
      "placeholder": "Enter something...",
      "rows": "5"
    },
    model: {
      value: _vm.form.description,
      callback: function ($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1)])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }